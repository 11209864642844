$categories-styles: (
  "pink": (active: $pink-800, default: $pink-600),
  "red": (active: $red-800, default: $red-600),
  "blue": (active: $blue-800, default: $blue-600),
  "orange": (active: $orange-800, default: $orange-600),
);

// Provides the styles for highlighted categories.
@mixin highlighed-category-links() {
  @each $name, $colors in $categories-styles {
    &-#{$name} {
      &:global(.active) {
        a {
          color: map-get($colors, 'active');
          border-bottom-color: #{map-get($colors, 'active')}!important;
        }
      }

      a {
        color: map-get($colors, 'default')!important;
        font-weight: 700;
      }
    }
  }
}

// Provides the styles for highlighted backgrounds.
@mixin highlighed-category-backgrounds() {
  @each $name, $colors in $categories-styles {
    &-#{$name} {
      background: #{map-get($colors, 'default')};
    }
  }
}


// Provides the styles for highlighted colors.
@mixin highlighed-category-colors() {
  @each $name, $colors in $categories-styles {
    &-#{$name} {
      color: #{map-get($colors, 'active')}!important;
    }
  }
}
