@import '../../../styles/gifts/sass/basics';

.bb-featured-categories {
  margin: 64px 0;

  &__heading {
    color: $turquoise-1200;
    text-align: center;
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 0!important;
    padding: 24px 0 12px;
  }

  &__items {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 auto;
    max-width: 100%;
    gap: 20px;
  }

  &__item {
    display: flex;
    align-items: center;
    overflow: hidden;

    a {
      display: flex;
      flex-direction: column;
      height: 100%;
      text-decoration: none;

      &:hover,
      &.hover,
      &:focus-visible {
        img {
          opacity: 0.6;
        }
      }
    }

    &-title {
      color: $white;
      font-size: 16px;
      font-weight: 700;
      line-height: 25px;
      padding: 8px 16px;
      height: 100%;
      align-items: center;
      display: flex;
      margin-top: -1px;

      background: $turquoise-1000;
      @include highlighed-category-backgrounds();
    }
  }

  @media all and (min-width: 500px) {
    margin: 80px 0;

    &__items {
      flex-direction: row;
      max-width: 100%;
    }

    &__item {
      width: calc(50% - 10px);

      &-title {
        font-size: 18px;
        line-height: 27px;
        padding: 12px 20px;
      }
    }
  }

  @include media-breakpoint-up(xl) {
    &__heading {
      font-size: 28px;
    }

    &__item {
      width: calc(33.33333% - 15px);
    }
  }
}
